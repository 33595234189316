// locks some of the react-md mobile styles to desktop
.lock-to-desktop {
  .md-floating-label--inactive-sized {
    font-size: 12px;
  }
  .md-floating-label--floating {
    transform: translate3d(0, 16px, 0);
  }
  .md-floating-label--inactive {
    transform: translate3d(0, 39px, 0);
  }
  .md-text-field--floating-margin {
    margin-top: 37px;
  }
  .md-text-field {
    font-size: 16px;
  }
  .md-divider--text-field {
    margin-bottom: 8px;
  }
  .md-list-tile {
    height: 48px;
  }
  .md-list--menu-restricted {
    max-height: 272px;
  }
}
