.bext-root {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  .bext-form {
    details summary {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: inline-block;
      }
    }
    .bext-download {
      display: inline-block;
    }
    .form-buttons {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: end;
      > * {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .bext-boards {
      display: flex;
      flex-direction: row;
    }

    .boards-url-search-button {
      top: 25px;
    }
  }
  .bext-blurb {
    h3 {
      white-space: normal;
    }
  }
}
