.create-org {
  .md-subheader {
    margin-top: 10px;
  }
  .create-org-button {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }
}
