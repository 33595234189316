.org-detail {
  hr {
    // visibility: hidden;
    display:none; // Orgs aren't editable. This removes the HR that makes every field look like a text field.
  }
  .sub-sub {
    margin-left: 20px;
    color: grey;
    font-size: 8px;
  }
  .ks-partner {
    float: right;
    line-height: 20px;
    background: rgba(234, 94, 35, 1);
    padding: 3px 5px;
    color: white;
    border-radius: 3px;
    margin-top: 10px;
  }
  .make-primary {
    margin-top: 10px;
  }
}
