@import 'globals';
@import 'lock-to-desktop';

$md-primary-color: $md-orange-500;
$md-secondary-color: $md-blue-600;
$md-body-desktop-font-size: 14px;
$md-btn-desktop-font-size: 14px;
$md-tab-desktop-font-size: 14px;
$md-list-desktop-font-size: 14px;
$md-text-field-desktop-font-size: 14px;
$md-dialog-use-flex-positioning: true;
$md-grid-desktop-breakpoint: 960px;

@include react-md-everything;

$sidebar-width: 250px;
$header-height: 50px;

html {
  background: transparent;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    15deg,
    rgba(0,0,0,0.65) 0%,
    rgba(0,0,0,0.55) 61%,
    rgba(0,0,0,0.45) 100%
  );

  .huddo-main-logo {
    position: absolute;
    left: 20px;
    bottom: 20px;
    height: 105px;
    width: 205px;
    background-image: url('huddo-logo-72.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  height: 100%;
  > div {
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: block;
  height: 100%;
  margin-left: $sidebar-width;
  max-width: 1500px;
}

@media screen and (max-width: 1024px) {
  .app {
    margin-left: 0;
  }
  .huddo-main-logo {
    display: none;
  }
  .app-body {
    margin-left: 10px;
    margin-right: 10px;
  }
  .huddo-lp-sidebar {
    background: $md-orange-500 !important;
  }
}

.app-header {
  height: $header-height;
  line-height: $header-height;
  font-size: 25px;

  a {
    text-decoration: none;
    color: white;
  }
}

.app-body {
  background: #f9f9f9;
  padding: 10px;
  height: calc(100% - 70px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow-y: auto;

  .md-subheader {
    background: rgba(0,0,0,0.65);
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 5px;
    font-weight: bold;
    overflow: hidden;
  }
}

.huddo-lp-sidebar {
  width: $sidebar-width;
  &,
  .md-list,
  .md-list .md-text,
  .md-list .md-icon {
    background: transparent;
  }
  .md-list {
    padding-top: 50px;
  }
  .md-list .md-text,
  .md-list .md-icon {
    color: white;
  }
}

.right-buttons {
  text-align: right;
  margin-top: 10px;

  margin-top: 10px;
}

.ks-orgLicences {
  width: 50%;
  margin-left: 50%;
}

.kl-orgLicencesTable table {
  width: 50%;
}

.ks-orgClientError {
  color: red;
  display: block;
}

.ks-orgClientErrorHide {
  color: red;
  display: none;
}

.ks-hide {
  display: none;
}

.ks-show {
  display: block;
}
